import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiRefresh } from 'react-icons/bi';
import { GoPencil } from 'react-icons/go';
import { RiAlertLine } from 'react-icons/ri';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import Separator from '~/components/Separator';
import { useManutencaoFiscal } from '../../ManutencaoFiscalContext';
import { ModalEditNFItem } from '../../components/ModalEditNFItem';
import { CustomDataGrid, CustomGridColumns } from './components/CustomDataGrid';
import { ButtonRow, ContainerScreens } from './styles';
import { ItemNFProps } from '../../protocols/ItemNFProps';

export const Fiscal: React.FC = () => {
  const {
    itensFiscal,
    handleOpenModal,
    handleItemFiscalEdit,
    handleRecalcularValores,
    handleCorrigirCFOPItens,
    loadingFiscal,
    conferida,
  } = useManutencaoFiscal();

  const associadoColumns: CustomGridColumns[] = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: 'cod_ncm',
      headerName: 'cod_ncm',
      hide: true,
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: 'num_item',
      headerName: 'Núm Item',
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: 'cfop',
      headerName: 'CFOP',
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: 'des_tributacao',
      headerName: 'Tributação',
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 130,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_gtin',
      headerName: 'GTIN',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'des_ncm',
      headerName: 'NCM',
      sortable: false,
      width: 230,
      disableColumnMenu: true,
    },
    {
      field: 'num_cest',
      headerName: 'CEST',
      sortable: false,
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      sortable: false,
      width: 230,
      disableColumnMenu: true,
    },
    {
      field: 'des_unidade',
      headerName: 'Unidade',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_embalagem',
      headerName: 'Embalagem',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_entrada',
      headerName: 'Qtd Entrada',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_total',
      headerName: 'Qtd Total',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_total',
      headerName: 'Val Total',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_desconto',
      headerName: 'Desconto',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_despesa_acessoria',
      headerName: 'Despesa Acessória',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'val_frete',
      headerName: 'Frete',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_bc_icms',
      headerName: 'BC ICMS OP',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },

    {
      field: 'val_icms',
      headerName: 'ICMS OP',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_outros',
      headerName: 'Outras',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_isento',
      headerName: 'Isento',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_bc_st',
      headerName: 'BC ST',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'val_icms_st',
      headerName: 'ST',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
    },
  ];

  const actions: CustomGridColumns[] = [
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'left',
      width: 80,
      renderCell: (row: any) => {
        const onEdit = () => {
          if (!conferida) {
            handleOpenModal(true);
            handleItemFiscalEdit(row);
          }
        };

        return (
          <ButtonRow
            disabled={conferida}
            style={{ width: '100%' }}
            type="button"
            onClick={onEdit}
            aria-label="Editar"
          >
            <GoPencil size={20} style={{ color: '#72ab90' }} />
          </ButtonRow>
        );
      },
    },
  ];

  const columns: CustomGridColumns[] = [...associadoColumns, ...actions];

  const itensFiscalSemDuplicatas: ItemNFProps[] = [];

  const uniqueItems: { [key: string]: boolean } = {};

  itensFiscal.forEach((item) => {
    const key = `${item.cod_produto}-${item.cod_seq_nf_item}-${item.val_total}`;

    if (!uniqueItems[key]) {
      itensFiscalSemDuplicatas.push(item);
      uniqueItems[key] = true;
    }
  });

  return (
    <ContainerScreens>
      <Separator labelText="Itens" />
      <Row>
        <Col md={12} sm={12} style={{ marginTop: '0.625rem', height: '25rem' }}>
          <CustomDataGrid
            rows={
              itensFiscalSemDuplicatas && !loadingFiscal
                ? itensFiscalSemDuplicatas
                : []
            }
            columns={columns}
            stickyMenuBackground="#eafffe"
          />
        </Col>
      </Row>
      <ModalEditNFItem />

      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col className="containerFiscal" md={12} sm={12}>
          <ConfirmButton
            style={{ width: '14.0625rem' }}
            onClick={() => {
              handleRecalcularValores();
            }}
          >
            <BiRefresh fontSize="20px" color="#fff" />
            RECALCULAR VALORES
          </ConfirmButton>
          <ClearButton
            style={{ width: '14.0625rem' }}
            onClick={() => {
              handleCorrigirCFOPItens();
            }}
          >
            <RiAlertLine fontSize="20px" color="#fff" />
            CORRIGIR CFOP DOS ITENS
          </ClearButton>
        </Col>
      </Row>
    </ContainerScreens>
  );
};
